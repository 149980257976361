.bio-page {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    font-family: 'europa', sans-serif;
}

.bio-page__title {
    max-width: 340px;
}

.bio-page__name {
    text-align: left;
    color: #111;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
}

.bio-page__sub {
    text-align: left;
    margin-bottom: 25px;
    font-weight: 400;
    font-style: normal;
    line-height: 2em;
    text-transform: none;
    letter-spacing: .04em;
    font-size: 14px;
    color: #aaa;
    margin-top: -20px;
}

.bio-page__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.bio-page__text {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.bio-page__text h3 {
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
    line-height: 1.1em;
    max-width: 100%;
}

.bio-page__text p {
    line-height: 1.5;
    color: #363636;
    margin-top: -15px;
    max-width: 100%;
}

.bio-page__image {
    width: 48%;
    margin-top: 25px
}

.bio-page__image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
