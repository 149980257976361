html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
    margin-top: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding: 0.25rem;
}

.footer__button-container {
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.footer__icon {
    font-size: 1.5rem;
    color: #363636;
    cursor: pointer;
    margin: 0.5rem;
}